import React from 'react'

export default ({frontmatter}) => {
    var lst = [];
    if (frontmatter.spotifylink) lst.push({link: frontmatter.spotifylink, title: 'SPOTIFY' });
    if (frontmatter.ituneslink) lst.push({link: frontmatter.ituneslink, title: 'ITUNES' });
    if (frontmatter.soundcloudlink) lst.push({link: frontmatter.soundcloudlink, title: 'SOUNDCLOUD' });
    if (frontmatter.facebooklink) lst.push({link: frontmatter.facebooklink, title: 'FACEBOOK' });
    if (frontmatter.deezerlink) lst.push({link: frontmatter.deezerlink, title: 'DEEZER' });

    if (lst.length < 1) {
        return <div />
    }

    return <div>
        <p className="sociallinks">
            {lst
                .map(x => <a href={x.link} target="_blank">{x.title}</a>)}
        </p>
    </div>
}
